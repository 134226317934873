export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout'),
    },
    {
      path: '/',
      name: 'Principal',
      component: () => import('@/layouts/DefaultContainer'),
      redirect: { name: 'Inicio' },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'changepass',
          name: 'changepass',
          component: () => import('@/views/Auth/ChangePass'),
        },

        {
          path: 'capacitaciones',
          meta: { label: 'Capacitaciones', rol_super: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Capacitaciones',
              path: '',
              component: () =>
                import('@/views/Capacitaciones/CapacitacionesTable'),
            },
            {
              path: 'create',
              name: 'Agregar Capacitación',
              component: () =>
                import('@/views/Capacitaciones/CapacitacionesCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Ver Capacitación',
              component: () =>
                import('@/views/Capacitaciones/CapacitacionesEdit'),
            },
            {
              path: 'imagen/:id',
              name: 'Gestionar Imagen',
              component: () =>
                import('@/views/Capacitaciones/CapacitacionesImagen'),
            },
            {
              path: 'comisiones/create/:id',
              name: 'Agregar Comisión',
              component: () => import('@/views/Comisiones/ComisionesCreate'),
            },
            {
              path: 'comisiones/edit/:id',
              name: 'Ver Comisión',
              component: () => import('@/views/Comisiones/ComisionesEdit'),
            },
            {
              path: 'comisiones/preinscripcion/:id',
              name: 'Datos Preinscripción',
              component: () =>
                import('@/views/Comisiones/ComisionesFechasPreinscripcion'),
            },
            {
              path: 'comisiones/list/:id',
              name: 'Listado de Inscriptos',
              component: () =>
                import('@/views/Comisiones/ComisionesInscriptos'),
            },
          ],
        },
        {
          path: 'capacitadores',
          meta: { label: 'Capacitadores' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Capacitadores',
              path: '',
              component: () =>
                import('@/views/Capacitadores/CapacitadoresTable'),
            },
            {
              path: 'create',
              name: 'Agregar Capacitador',
              component: () =>
                import('@/views/Capacitadores/CapacitadoresCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Ver Capacitador',
              component: () =>
                import('@/views/Capacitadores/CapacitadoresEdit'),
            },
            {
              path: 'clearpass/:id',
              name: 'Blanquear Capacitador',
              component: () =>
                import('@/views/Capacitadores/CapacitadoresClearPass'),
            },
            {
              path: 'capacitaciones/list/:id',
              name: 'Capacitaciones',
              component: () =>
                import('@/views/Capacitadores/CapacitadoresCapacitaciones'),
            },
          ],
        },
        {
          path: 'administradores',
          meta: { label: 'Administradores', rol_super: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Administradores',
              path: '',
              component: () =>
                import('@/views/Administradores/AdministradoresTable'),
            },
            {
              path: 'create',
              name: 'Agregar Administrador',
              component: () =>
                import('@/views/Administradores/AdministradoresCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Ver Administrador',
              component: () =>
                import('@/views/Administradores/AdministradoresEdit'),
            },
            {
              path: 'clearpass/:id',
              name: 'Blanquear Administrador',
              component: () =>
                import('@/views/Administradores/AdministradoresClearPass'),
            },
          ],
        },
        {
          path: 'docentes',
          meta: { label: 'Docentes', rol_super_visor: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Docentes',
              path: '',
              component: () => import('@/views/Docentes/DocentesTable'),
            },
            {
              path: 'create',
              name: 'Agregar Docente',
              component: () => import('@/views/Docentes/DocentesCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Docente',
              component: () => import('@/views/Docentes/DocentesEdit'),
            },
            {
              path: 'clearpass/:id',
              name: 'Blanquear Usuario',
              component: () => import('@/views/Docentes/DocentesClearPass'),
            },
            {
              path: 'inscripciones/:id',
              name: 'Inscripciones Docente',
              component: () => import('@/views/Docentes/DocentesInscripciones'),
            },
          ],
        },
        //  {
        //    path: 'Inscripciones',
        //    name: 'inscripciones',
        //    component: () => import('@/views/Inscripciones/Inscripciones_cargar'),
        //    meta: { label: 'Inscripciones', rol_super: true },
        //  },
        {
          path: 'inscripciones',
          name: 'Inscripciones',
          component: () =>
            import('@/views/Inscripciones/InscripcionesPrincipal'),
          meta: { label: 'Inscripciones', rol_super: true },
          redirect: { name: 'InscripcionesCargar' }, // Redirigir a la ruta 'Cargar' por defecto
          children: [
            {
              path: 'cargar',
              name: 'InscripcionesCargar',
              component: () =>
                import('@/views/Inscripciones/Inscripciones_cargar'),
              meta: { label: 'Cargar Inscripciones' },
            },
            {
              path: 'consulta',
              name: 'InscripcionesConsulta',
              component: () =>
                import('@/views/Inscripciones/InscripcionesTable'),
              meta: { label: 'Consulta de Inscripciones' },
            },
          ],
        },
        {
          path: 'logs',
          name: 'Logs Sistema',
          component: () => import('@/views/Logs/LogsTable'),
          meta: { label: 'Logs', rol_super: true },
        },
        // RUTAS PANEL CAPACITADOR
        {
          path: 'panelcapacitador',
          meta: { label: 'Capacitadores' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Comisiones',
              path: 'miscapacitaciones',
              component: () =>
                import('@/views/vistaCapacitador/vistaCapacitadoresTable'),
            },
            {
              name: 'Ver Comision',
              path: 'micapacitacion',
              component: () =>
                import('@/views/vistaCapacitador/vistaCapacitadoresTable'),
            },
            {
              name: 'Ver Inscriptos',
              path: 'verinscriptos/:id',
              component: () =>
                import('@/views/vistaCapacitador/vistaCapacitadoresInscriptos'),
            },
            {
              name: 'Ver Planillas',
              path: 'verplanilla/:id',
              component: () =>
                import(
                  '@/views/vistaCapacitador/vistaCapacitadoresListadoInscriptos'
                ),
            },
            {
              name: 'Ver Evaluación',
              path: 'verevaluacion/:id',
              component: () =>
                import(
                  '@/views/vistaCapacitador/vistaCapacitadoresEvaluacionMasivas'
                ),
            },
          ],
        },
        // RUTAS PANEL DIRECCION/ENTIDAD OFERENTE
        {
          path: 'paneldireccion',
          meta: { label: 'Direccion' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Capacitaciones de la Dirección',
              path: '',
              component: () =>
                import('@/views/vistaDireccion/vdCapacitacionesTable'),
            },
            {
              path: 'create',
              name: 'Agregar Capacitación de la Dirección',
              component: () =>
                import('@/views/vistaDireccion/vdCapacitacionesCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Ver Capacitación de la Dirección',
              component: () =>
                import('@/views/vistaDireccion/vdCapacitacionesEdit'),
            },
            {
              path: 'comisiones/create/:id',
              name: 'Agregar Comisión',
              component: () =>
                import('@/views/vistaDireccion/vdComisionesCreate'),
            },
            {
              path: 'comisiones/edit/:id',
              name: 'Ver Comisión de la Dirección',
              component: () =>
                import('@/views/vistaDireccion/vdComisionesEdit'),
            },
            {
              path: 'comisiones/list/:id',
              name: 'Listado de Inscriptos de la Dirección',
              component: () =>
                import('@/views/vistaDireccion/vdComisionesInscriptos'),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
  ],
}
